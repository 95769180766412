<template>
  <b-card title="Edit User">
    <b-col cols="12">
      <b-row class="mb-2">
        <b-col cols="4">
          <b-button variant="primary" :to="{ name: 'user-management' }">
            Back to List User
          </b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <validation-observer ref="editAdmin">
            <b-form-group>
              <span>Username <span style="color:red">(*)</span></span>
              <validation-provider
                v-slot="{ errors }"
                name="Username"
                rules="required"
              >
                <b-form-input
                  v-model="userForm.name"
                  type="text"
                  placeholder="Username"
                  :state="errors.length > 0 ? false : null"
                  style="margin-top:5px !important;"
                ></b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group>
              <span>Email <span style="color:red">(*)</span></span>
              <validation-provider
                name="Email"
                rules="required|email"
                v-slot="{ errors }"
              >
                <b-form-input
                  v-model="userForm.email"
                  type="email"
                  placeholder="Email"
                  :state="errors.length > 0 ? false : null"
                  style="margin-top:5px !important;"
                ></b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group>
              <span>Role <span style="color:red">(*)</span></span>
              <v-select
                v-model="userForm.roleRef"
                placeholder="----- Select Role -----"
                :options="roles"
                label="label"
                @input="clearPermission"
                :clearable="false"
                style="margin-top:5px !important;"
                class="mb-1"
              />
            </b-form-group>

            <b-form-group label="Dealer" v-if="checkEditRole">
              
              <v-select
                v-model="userForm.dealer"
                placeholder="----- Select Dealer -----"
                :options="allDealers"
                label="dealerName"
                :clearable="true"
                class="mb-1"
              />
              <p></p>
            </b-form-group>
            <b-form-group>
              <b-form-checkbox
                id="checkbox-1"
                v-model="userForm.deactivated"
                name="checkbox-1"
              >
              Deactivate User
              </b-form-checkbox>
            </b-form-group>
          </validation-observer>
        </b-col>
        <b-col cols="12">
          <b-button variant="success" @click="saveEdit"> Save </b-button
          >&nbsp;&nbsp;
          <!-- <b-button variant="outline-primary" v-if="tempAllPO===false && this.userForm.roleRef.name==='Dealer'" @click="tempAllPO=true">
            Add Dealer
          </b-button>&nbsp;&nbsp; -->
        </b-col>
      </b-row>
    </b-col>
  </b-card>
</template>

<script>
import { mapActions } from "vuex";
import axios from "@/axios";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email } from "@validations";
import { sleep } from "@/utils/utils.js";

export default {
  components: {
    "validation-observer": ValidationObserver,
    "validation-provider": ValidationProvider,
  },
  props: ["id"],
  data() {
    return {
      userForm: {},
      tempAllPO: false,
      userFormCadangan: {},
    };
  },
  methods: {
    saveEdit() {
      let forSave = this.userForm;
      let forSaveCadangan = this.userFormCadangan;
      console.log("this.userFormCadangan", this.userFormCadangan);
      console.log("checkEditRole", this.checkEditRole);
      console.log("forSave", this.userForm);
      if (forSave.dealer) {
        forSave.dealer = forSave.dealer._id;
      }
      if (forSave.roleRef.name !== "Dealer") {
        forSave.dealer = null;
      }
      this.$refs.editAdmin.validate().then((result) => {
        if (result) {
          this.updateUser({ id: this.id, data: forSave })
            .then(async (response) => {
              this.$bvToast.toast(
                `Successfully updated user ${this.userForm.name}`,
                {
                  title: "Success",
                  variant: "success",
                  solid: true,
                }
              );

              //this is for save user id to dealer
              console.log({ response });
              console.log("this.userFormCadangan", this.userFormCadangan);
              let { dealer } = response;
              if (dealer !== null) {
                this.updateDealerV2({
                  userID: this.id,
                  dealerID: this.userForm.dealer,
                }).then(async (response) => {
                  this.$bvToast.toast(
                    `Successfully update dealer for ${this.userForm.name}`
                  );
                });
                // let findPrevDealer = this.allDealers.filter((x) => {
                //   // return x.users
                //   return x._id === this.userFormCadangan.dealer;
                // });
                // console.log("findPrevDealer", findPrevDealer);
                // //this is for checking the dealer has been selected on this form
                // let searchDealer = this.allDealers.find((x) => {
                //   return x._id === dealer;
                // });
                // console.log("searchDealer", searchDealer);
                // //console.log({searchDealer});
                // //console.log('findPrevDealer',findPrevDealer);
                // //This is if the previous dealer was different from the one just chosen
                // if (findPrevDealer[0]._id !== searchDealer._id) {
                //   //this is process of deleting the existing user at the previous dealer
                //   let indexPrevUser = findPrevDealer[0].users.indexOf(this.id);
                //   console.log("indexPrevUser", indexPrevUser);
                //   findPrevDealer[0].users.splice(indexPrevUser, 1);
                //   //this is process save user at new dealer
                //   searchDealer.users = [this.id, ...searchDealer.users];
                //   //console.log('searchDealer', searchDealer)
                //   //this is process to update dealer data db
                //   this.updateDealer(searchDealer);
                //   this.updateDealer(findPrevDealer[0]);
                // }
              }
              await sleep(1000);
              this.$router.go(-1);
            })
            .catch((e) => {
              console.log({ e });
              this.$bvToast.toast(
                `You have failed to create a user ${this.userForm.name}`,
                {
                  title: "Failed",
                  variant: "danger",
                  solid: true,
                }
              );
            });
          this.loadDealer();
        }
      });
    },
    clearPermission() {
      this.tempAllPO = false;
      if (this.userForm.roleRef.name === "Dealer") {
        this.tempAllPO = true;
      }
    },
    ...mapActions({
      updateUser: "auth/updateUser",
      getUser: "auth/getUser",
      updateDealer: "dealer/updateDealer",
      updateDealerV2: "dealer/updateDealerV2",
    }),
    loadDealer() {
      if (this.userForm.dealer) {
        var searchDealer = this.allDealers.find((e) => {
          return e._id === this.userForm.dealer;
        });
        this.userForm.dealer = Object.assign(searchDealer);
      }
      return this.userForm.dealer;
    },
  },
  computed: {
    roles() {
      var getRoles = this.$store.getters["role/getRole"]
        ? this.$store.getters["role/getRole"]
            .filter((el) => {
              return el.name !== "Super Admin" || el.level !== "Super Admin";
            })
            .map((el) => {
              return {
                ...el,
                label: `${el.name} - ${el.level}`,
              };
            })
        : [];
      return getRoles;
    },
    allDealers() {
      var tempDealers = this.$store.getters["dealer/getDealers"].filter(
        (x) => x.status === ""
      );
      return tempDealers;
    },
    permissionAllPO() {
      //this is filter depend selected role. get all permission, and search permission role for purchase order menu
      var accessAllPO = false;
      console.log("this.userForm.roleRef", this.userForm.roleRef);
      if (this.userForm.roleRef.permission) {
        var tempPermission = this.userForm.roleRef.permission;
        var searchOrder = tempPermission.order_menu.find((e) => {
          return e.name === "Order";
        });
        console.log({ searchOrder });
        accessAllPO = searchOrder.all;
        console.log({ accessAllPO });
      }
      return accessAllPO;
    },
    checkEditRole() {
      var result = false;
      /*
        Check from the form select role, is there permission to "access all po" is true or not
        if it's false and the selected label is dealer (temporary hardcoded), only role dealer can choose dealer master
      */
      if (
        this.permissionAllPO === false &&
        this.userForm.roleRef.name === "Dealer"
      ) {
        result = true;
      }
      // check marker if this user has dealer data from db or not
      if (this.tempAllPO === true) {
        result = true;
      }
      return result;
    },
  },
  async mounted() {
    // console.log('id',this.id)
    await this.$store.dispatch("dealer/getAllDealers");
    await this.$store
      .dispatch("role/fetchRole")
      .catch((err) => console.log(err));

    await this.getUser(this.id)
      .then((res) => {
        //console.log('res MOUNTED',res.data)
        this.userForm = res.data;
        this.userFormCadangan = { ...res.data };
        //console.log('this.userFormCadangN MOUNTED', this.userFormCadangan)
        this.userForm.roleRef
          ? (this.userForm.roleRef = Object.assign(this.userForm.roleRef, {
              label: `${res.data.roleRef.name} - ${res.data.roleRef.level} `,
            }))
          : null;
        if (this.userForm.dealer) {
          var searchDealer = this.allDealers.find((e) => {
            return e._id === this.userForm.dealer;
          });
          this.userForm.dealer = Object.assign(searchDealer);
          //if the db user has dealer data, it will be given a marker to "true"
          this.tempAllPO = true;
        }
      })
      .catch((e) => {
        console.log(e);
      });
  },
  created() {
    document.title = "User | RSP";
  },
};
</script>
